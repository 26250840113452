import { useEffect, useState } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { TobBar } from "src/shared/components/top-bar";
import TicketInfo from "./components/steps/ticket-info";
import { useTranslation } from "react-i18next";
import {
  useCancelCarRequestMutation,
  useCreateCarRequestMutation,
  useGetCarRequestTransaction,
  usePayMutation,
  useVerifyRedirectedPaymentMutation,
} from "src/api/car-request";
import TimeAndGate from "./components/steps/tima-and-gate";
import { useParams, useSearchParams } from "react-router-dom";
import RequestedSuccessfully from "./components/steps/requested-successfully";
import FailedTicket from "./components/failed-ticket";
import CheckedOutTicket from "./components/checked-out-ticket";
import PaymentType from "./components/steps/payment-type";
import { LoadingButton } from "@mui/lab";
import { defaultSteps, schema } from "./constants";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormData } from "./constants";
import CancelledSuccessfully from "./components/steps/cancelled";
import PaymentFailed from "./components/payment-failed";

const CarRequestPage = () => {
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const redirectedPaymentSessionId = searchParams.get("cko-session-id");
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState(defaultSteps);
  const [applePaySession, setApplePaySession] = useState<any>();
  const [payStatus, setPayStatus] = useState<
    "free" | "paid" | "notPaid" | "notPaidNotFixed" | undefined
  >();
  const [open, setOpen] = useState(false);

  // APIS
  const { data, isLoading, error, status, refetch, isFetching } =
    useGetCarRequestTransaction(params.id || "");
  const {
    mutate,
    status: createReqStatus,
    isPending,
  } = useCreateCarRequestMutation();
  const {
    mutate: mutateCancelReq,
    status: cancelReqStatus,
    isPending: isPendingCancel,
  } = useCancelCarRequestMutation();
  const {
    mutate: mutatePay,
    isPending: isPendingPay,
    status: statusPay,
    error: errorPay,
    data: payResponse,
  } = usePayMutation();
  const {
    mutate: mutateVerifyPay,
    isPending: isPendingVerifyPay,
    status: statusVerifyPay,
    error: errorVerifyPay,
  } = useVerifyRedirectedPaymentMutation();

  // React hook form
  const methods = useForm<FormData>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      time: "",
      gate: "",
    },
  });
  const { trigger, getValues, watch, setValue } = methods;

  const isFreeTicket = data?.data.enableFreeLocation || data?.data.price === 0;

  const handleClose = () => {
    setOpen(false);
  };

  const isPaymentStep = currentStep === 2 && payStatus === "notPaid";

  // Validate Steps
  const validate = async () => {
    let stepFields = steps[currentStep].fields;
    if (currentStep === 1 && data && data.data.exitGates.length < 2) {
      stepFields = ["time"];
    }
    const validStep = await trigger(stepFields as any[], { shouldFocus: true });
    return validStep;
  };

  const handleCarRequest = () => {
    mutate({
      ticketId: params.id || "",
      selTime: Number(getValues("time")),
      ...(data && data.data.exitGates.length > 1
        ? { selGate: getValues("gate") }
        : {}),
    });
  };

  const goNext = async () => {
    const valid = await validate();
    if (!valid) return;
    if (currentStep <= steps.length - 1) {
      // Gate and time step
      if (currentStep === 1 && payStatus !== "notPaid") {
        handleCarRequest();
        return;
      } else if (currentStep === 1 && payStatus === "notPaid") {
        setCurrentStep((prev) => prev + 1);
        return;
      }
      // Payment step
      if (currentStep === 2 && payStatus === "notPaid") {
        if (getValues("paymentType") === "cashier") {
          handleCarRequest();
        } else if (getValues("paymentType") === "card") {
          const expiryArr = getValues("expiry_at")?.split("/") || [];
          const month = expiryArr[0];
          const year = expiryArr[1];
          mutatePay({
            ticketId: params.id as string,
            type: "card",
            number: getValues("cardNumber") as string,
            expiry_month: month,
            expiry_year: Number(year),
            name: getValues("name") as string,
            cvv: Number(getValues("cvv")),
            selTime: Number(getValues("time")),
            selGate: getValues("gate"),
          });
        }
        return;
      }
      // Requested successfully step. Next step: cancellation
      if (currentStep === steps.length - 2) {
        mutateCancelReq({
          ticketId: params.id as string,
          ticketNum: data?.data.ticketNumber as string,
        });
        return;
      }
      // Cancelled step. Next step: date and time to re-request
      if (currentStep === steps.length - 1) {
        setCurrentStep(1);
        return;
      }
      setCurrentStep((prev) => prev + 1);
    }
  };

  // Get car request data success handling
  useEffect(() => {
    console.log({ data });
    // When we have redirected payment session Id
    if (redirectedPaymentSessionId) {
      mutateVerifyPay({
        ticketId: params.id as string,
        sessionId: redirectedPaymentSessionId,
      });
    }
    if (status === "success") {
      // If already requested
      if (data.data.isRequested) {
        // If there is payment step
        if (
          !isFreeTicket &&
          !data?.data.paymentInfo &&
          data.data.valetServicePriceType === "fixed"
        ) {
          setCurrentStep(3);
        } else {
          setCurrentStep(2);
        }
      }

      //Remove payment step after payment and refetching
      if (steps.length === 5) {
        setSteps(defaultSteps);
        setCurrentStep((prev) => prev - 1);
      }

      if (isFreeTicket) {
        setPayStatus("free");
      } else if (!!data?.data.paymentInfo) {
        setPayStatus("paid");
      } else if (data.data.valetServicePriceType === "fixed") {
        setPayStatus("notPaid");
        if (steps.length === 4) {
          const paymentStep = {
            id: "paymentType",
            fields: ["paymentType", "name", "number", "expiry_at", "cvv"],
          };
          const updatedSteps = [...defaultSteps];
          updatedSteps.splice(2, 0, paymentStep);
          setSteps(updatedSteps);
        }
      } else {
        setPayStatus("notPaidNotFixed");
      }
    }
  }, [status, isFetching]);

  const onPaymentSuccess = () => {
    refetch();
    handleCarRequest();
  };

  //Create Req Success handling
  useEffect(() => {
    if (createReqStatus === "success") {
      const requestedStepIndex = steps.findIndex(
        (step) => step.id === "requested"
      );
      setCurrentStep(requestedStepIndex);
    }
  }, [createReqStatus]);

  //Cancel Req Success handling
  useEffect(() => {
    if (cancelReqStatus === "success") {
      setCurrentStep((prev) => prev + 1);
    }
  }, [cancelReqStatus]);

  //Pay success and error handling
  useEffect(() => {
    if (statusPay === "success") {
      onPaymentSuccess();
      // apple pay success
      if (watch("paymentType") === "applepay") {
        console.log("apple pay succeeded");
        const windowCopy: any = window;
        applePaySession?.completePayment(
          windowCopy?.ApplePaySession?.STATUS_SUCCESS
        );
      }
    } else if (statusPay === "error") {
      // apple pay failure
      if (watch("paymentType") === "applepay") {
        console.log("apple pay failed", { payResponse, errorPay });
        const windowCopy: any = window;
        applePaySession?.completePayment(
          windowCopy?.ApplePaySession?.STATUS_FAILURE
        );
      }
      // Type of card that needs redirection
      if (
        errorPay?.response?.data?.data?.status === "Pending" &&
        !!errorPay?.response?.data?.data?.redirectLink
      ) {
        window.location.href = errorPay?.response?.data?.data?.redirectLink;
        return;
      }
      setOpen(true);
    }
  }, [statusPay]);

  //Verify payment req success handling (after redirection)
  useEffect(() => {
    if (statusVerifyPay === "success") {
      setValue("time", searchParams.get("time") as string);
      setValue("gate", searchParams.get("gate") as string);
      onPaymentSuccess();
      setSearchParams();
    } else if (statusVerifyPay === "error") {
      setValue("time", searchParams.get("time") as string);
      setValue("gate", searchParams.get("gate") as string);
      const paymentStepIndex = steps.findIndex(
        (step) => step.id === "paymentType"
      );
      // To make sure that it's still unpaid
      if (paymentStepIndex !== -1) {
        setOpen(true);
        setCurrentStep(paymentStepIndex);
      }
      setSearchParams();
    }
  }, [statusVerifyPay]);

  return (
    <Box display={"flex"} justifyContent={"center"} minHeight={"95vh"}>
      <Box
        boxShadow={{ xs: "unset", sm: "0px 0px 10px rgba(255, 255, 255, 0.3)" }}
        borderRadius={"50px"}
        minWidth={{ xs: "100%", sm: "375px" }}
        maxWidth={{ xs: "100%", sm: "375px" }}
        bgcolor={"#0E0E0E"}
        px={"33px"}
        pb={"48px"}
        display={"flex"}
        flexDirection={"column"}
      >
        <TobBar />

        {isFetching || isPendingVerifyPay || isPending ? (
          <Box pt={"200px"} display={"flex"} justifyContent={"center"}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {error?.response.status === 400 ? (
              <FailedTicket />
            ) : (
              data && (
                <>
                  {!!data.data.checkOutTime ? (
                    <CheckedOutTicket />
                  ) : (
                    <>
                      {/* Steps */}
                      <FormProvider {...methods}>
                        {currentStep === 0 && <TicketInfo data={data?.data} />}
                        {currentStep === 1 && (
                          <TimeAndGate exitGates={data?.data.exitGates || []} />
                        )}
                        {payStatus === "notPaid" && currentStep === 2 && (
                          <PaymentType
                            price={data.data.price}
                            mutatePay={mutatePay}
                            setApplePaySession={setApplePaySession}
                          />
                        )}
                        {currentStep === steps.length - 2 && (
                          <RequestedSuccessfully data={data?.data} />
                        )}
                        {currentStep === steps.length - 1 && (
                          <CancelledSuccessfully data={data?.data} />
                        )}
                      </FormProvider>

                      {/* Stepper */}
                      <Stack flexGrow={1}>
                        <Box mt={"auto"}>
                          {currentStep === 0 && (
                            <Typography
                              color={"textPrimary"}
                              fontSize={"12px"}
                              textAlign={"center"}
                            >
                              {t("pressButtonToRequestCar")}
                            </Typography>
                          )}
                          {!(
                            isPaymentStep &&
                            (watch("paymentType") === "applepay" ||
                              watch("paymentType") === "googlepay")
                          ) && (
                            <LoadingButton
                              onClick={goNext}
                              fullWidth
                              variant={
                                currentStep === steps.length - 2
                                  ? "outlined"
                                  : "contained"
                              }
                              color={
                                currentStep === steps.length - 2
                                  ? "error"
                                  : "primary"
                              }
                              size="large"
                              loading={
                                isPending || isPendingCancel || isPendingPay
                              }
                              sx={{ color: "#FFFDF8", mt: 2 }}
                            >
                              {currentStep === 0 ||
                              currentStep === steps.length - 1
                                ? t("requestUrCar")
                                : currentStep === steps.length - 2
                                ? t("cancelReq")
                                : t("continue")}
                            </LoadingButton>
                          )}
                        </Box>
                      </Stack>
                    </>
                  )}
                </>
              )
            )}
          </>
        )}
      </Box>
      <PaymentFailed open={open} handleClose={handleClose} />
    </Box>
  );
};

export default CarRequestPage;
