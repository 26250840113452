import { Stack, Box, Typography } from "@mui/material";
import { t } from "i18next";
import moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import StatusChip from "src/shared/components/status-chip";
import { ICarRequest } from "src/types/car-request";

const TicketInfoBox = ({
  data,
  hiddenPaymentStatus = false,
}: {
  data: ICarRequest;
  hiddenPaymentStatus?: boolean;
}) => {
  const {
    ticketNumber,
    plateNumber,
    checkInTime,
    enableFreeLocation,
    paymentInfo,
    price,
  } = data || {};
  const isFreeTicket = enableFreeLocation || price === 0;

  return (
    <>
      <Stack alignItems={"center"} mb={1}>
        <Box bgcolor={"white"} borderRadius={"6px"} pt={1.5} pb={1} px={1}>
          <QRCodeSVG value={ticketNumber} />
        </Box>
      </Stack>

      <Stack gap={1.5}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography color={"textPrimary"} fontSize={"14px"}>
            {t("ticketNum")}
          </Typography>
          <Typography color={"textPrimary"} fontWeight={700}>
            {ticketNumber}
          </Typography>
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography color={"textPrimary"} fontSize={"14px"}>
            {t("vehicleNum")}
          </Typography>
          <Typography color={"textPrimary"} fontWeight={700}>
            {plateNumber}
          </Typography>
        </Stack>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography color={"textPrimary"} fontSize={"14px"}>
            {t("date")}
          </Typography>
          <Typography color={"textPrimary"} fontWeight={700}>
            {moment(checkInTime).format("DD/MM/YYYY")}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography color={"textPrimary"} fontSize={"14px"}>
            {t("fees")}
          </Typography>
          {isFreeTicket ? (
            <StatusChip label="Free" />
          ) : (
            <Typography color={"textPrimary"} fontWeight={700}>
              {price} {t("sar")}
            </Typography>
          )}
        </Stack>

        {!isFreeTicket && !hiddenPaymentStatus && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography color={"textPrimary"} fontSize={"14px"}>
              {t("paymentStatus")}
            </Typography>
            <StatusChip
              label={!!paymentInfo ? t("paid") : t("unpaid")}
              variant={!!paymentInfo ? "success" : "error"}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export { TicketInfoBox };
