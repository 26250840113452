import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import DoneIcon from "src/assets/svg-icons/done.svg";
import DangerIcon from "src/assets/svg-icons/danger.svg";
import { QRCodeSVG } from "qrcode.react";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface IProps {
  bookingData: any;
}

const BookedSuccessfully: React.FC<IProps> = ({ bookingData }) => {
  const { t, i18n } = useTranslation();

  return (
    <Box borderRadius={"12px"} overflow={"hidden"}>
      <Box
        bgcolor={"#272727"}
        padding={{ xs: "30px 16px", md: "30px 113px 100px 113px" }}
      >
        <Stack alignItems={"center"}>
          <img src={DoneIcon} alt="" />
          <Typography
            color={"primary"}
            fontSize={"24px"}
            fontWeight={700}
            mb={1}
          >
            {t("bookedSuccessfully")}
          </Typography>
          <Typography fontSize={"14px"}>
            {t("moreChargesMightBeApplied")}
          </Typography>
        </Stack>
        {/* divider */}
        <Box my={4} bgcolor={"#383838"} height={"1px"} width={"100%"} />
        <Grid container spacing={0}>
          <Grid container xs={12} md={3.8}>
            <Stack
              p={"30px 34px 26px 33px"}
              border={"1px solid"}
              borderColor={"#D4AD53"}
              borderRadius={"6px"}
              alignItems={"center"}
              mb={{ xs: 2, md: 0 }}
            >
              <QRCodeSVG value={bookingData.ticketId} />
              <Box mt={"14px"} mb={1}>
                <img src={DangerIcon} alt="" />
              </Box>
              <Typography align="center">
                {t("noteThatIfNoArriveWithinOneHour")}
              </Typography>
              <Typography color={"primary"} align="center">
                {t("paymentRefundIsNotAllowed")}
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={0} md={0.4} />
          <Grid container xs={12} md={7.8}>
            <Stack width={"100%"} gap={2}>
              <Box
                bgcolor={"#222222"}
                borderRadius={"6px"}
                p={"31px 25px"}
                display={"flex"}
                gap={"51px"}
              >
                <Typography
                  minWidth={"100px"}
                  fontSize={"14px"}
                  color={"#737373"}
                >
                  {t("ticketNum")}
                </Typography>
                <Typography
                  sx={{
                    direction: i18n.language === "ar" ? "rtl" : "ltr",
                  }}
                >
                  {bookingData.ticketId}
                </Typography>
              </Box>
              <Box
                bgcolor={"#222222"}
                borderRadius={"6px"}
                p={"31px 25px"}
                display={"flex"}
                gap={"51px"}
              >
                <Typography
                  minWidth={"100px"}
                  fontSize={"14px"}
                  color={"#737373"}
                >
                  {t("arrivingDate")}
                </Typography>
                <Typography>
                  {moment(bookingData.arrivingTime).format("D/M/YYYY")}
                </Typography>
              </Box>
              <Box
                bgcolor={"#222222"}
                borderRadius={"6px"}
                p={"31px 25px"}
                display={"flex"}
                gap={"51px"}
              >
                <Typography
                  minWidth={"100px"}
                  fontSize={"14px"}
                  color={"#737373"}
                >
                  {t("arrivingTime")}
                </Typography>
                <Typography
                  sx={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
                >
                  {moment(bookingData.arrivingTime).format("h:mm A")}
                </Typography>
              </Box>
              <Box
                bgcolor={"#222222"}
                borderRadius={"6px"}
                p={"31px 25px"}
                display={"flex"}
                gap={"51px"}
              >
                <Typography
                  minWidth={"100px"}
                  fontSize={"14px"}
                  color={"#737373"}
                >
                  {t("locationName")}
                </Typography>
                <Typography>{bookingData.locationName}</Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BookedSuccessfully;
